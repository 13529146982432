import React from 'react'
import {TranslationController} from "../../../controller/menager";
import {formatDate} from "../../../helpers/dateHelper";
import Button from "../../../components/Button";
import {DATA_OBJECT} from "../../../controller/dataController";
import Confirm from "../../../components/Confirm";
import {api} from "../../../lib/axios";
import {Icon} from "semantic-ui-react";
import SnackBar from "../../../components/Snack";

export default class OrderServicesActionsWidget extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            row: props.row
        }
    }

    componentDidMount() {
        this.onLoad();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.row !== this.props.row) {
            this.setState({row: this.props.row}, () => {
                this.onLoad();
            });
        }
    }

    onLoad() {
        this.tryToUpdateMmDocumentState();
    }

    tryToUpdateMmDocumentState() {
        const row = this.state.row;

        if (row && (!row.numberMM && row.hasMmDocumentAttempt)) {
            setTimeout(() => {
                this.refreshMmDocumentStatus()
            }, 5000)
        }
    }

    refreshMmDocumentStatus() {
        api().post("/api/orderService", {
            id: this.props.row._id,
        })
            .then(r => {
                if (r.data && r.data.data) {
                    const orderServiceDetails = r.data.data[0];

                    if (orderServiceDetails) {
                        this.setState({
                            row: orderServiceDetails
                        }, () => this.onLoad())

                        if (this.props.refsToRefresh) {
                            const components = this.props.refsToRefresh();

                            if (components) {
                                components.forEach(component => {
                                    if (component && component.state.row._id === orderServiceDetails._id) {
                                        component.setState({
                                            row: orderServiceDetails
                                        })
                                    }
                                })
                            }
                        }
                    }
                }
            })
    }

    render() {
        const row = this.state.row;

        return (
            <div>
                {(row.numberMM && row.mmDocument) &&
                    <React.Fragment>
                        <div style={{
                            fontWeight: 600,
                            marginBottom: 15
                        }}>
                            <div style={{
                                fontWeight: 600,
                                color: "#848484"
                            }}>{TranslationController.translate("Numer dokumentu MM")}:</div>
                            {row.numberMM}
                        </div>

                        <div style={{
                            fontWeight: 600,
                            marginBottom: 15
                        }}>
                            <div style={{
                                fontWeight: 600,
                                color: "#848484"
                            }}>{TranslationController.translate("Data aktualizacji")}:</div>
                            {formatDate(row.mmDocument.createdAt).dateTimeSystem}
                        </div>

                        <div style={{
                            marginTop: 15
                        }}>
                            <Button
                                color='blue'
                                content='Pobierz dokument MM'
                                icon='download'
                                label={{ basic: true, color: 'blue', pointing: 'left', content: '.pdf' }}
                                onClick={() => {
                                    window.open(row.mmDocument.pdf)
                                }}
                            />
                        </div>
                    </React.Fragment>
                }

                {row.status === "send" &&
                    <div style={{
                        fontWeight: 600,
                        marginTop: 15
                    }}>
                        <div style={{
                            fontWeight: 600,
                            color: "#20ca45"
                        }}>{TranslationController.translate("Zamówienie zostało wysłane.")}</div>
                    </div>
                }

                {row.status === "rejected" &&
                    <div style={{
                        fontWeight: 600,
                        marginTop: 15
                    }}>
                        <div style={{
                            fontWeight: 600,
                            color: "#b23530"
                        }}>{TranslationController.translate("Zamówienie zostało anulowane z powodu braku dostępności towarów.")}</div>
                    </div>
                }

                <React.Fragment>
                    {DATA_OBJECT["user"] && DATA_OBJECT["user"].login === "root" &&
                        <div style={{
                            marginTop: 15
                        }}>
                            <Button onClick={() => {
                                return Confirm(
                                    "Czy na usunąć ten record?",
                                    "",
                                    () => {
                                        api().post("/api/orderService/remove", {
                                            id: row._id,
                                        }).then((r) => {
                                            this.props.tableRefresh();
                                        })
                                    })
                            }
                            }>[Dev] Usuń record</Button>
                        </div>
                    }

                    {!["rejected", "send", "canceled"].includes(row.status) &&
                        <div>
                            <div className={"ui button force-roboto"} style={{marginTop: 15}} onClick={() =>  window.open(this.props.selectButtonUrl, '_self')}>{
                                row.status === "accepted" ? "Obsłuż ponownie" : "Obsłuż"
                            }</div>
                        </div>
                    }

                    {(!row.numberMM && row.hasMmDocumentAttempt)
                        && <div>
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                background: "rgba(70,175,62,0.12)",
                                padding: 20,
                                borderRadius: 20,
                                marginTop: 20
                            }}>
                                <div style={{
                                    fontSize: 20,
                                    color: "#46af3e"
                                }}>
                                    <Icon style={{color: "#46af3e"}} className={"tm-main-icon"} name={"checkmark"}/>
                                </div>

                                <div style={{
                                    color: "#46af3e",
                                    fontWeight: 700,
                                    textAlign: "center"
                                }}>
                                    Dokument MM został zarejestrowany i jest w trakcie tworzenia. Będzie dostępny za kilka minut.
                                </div>
                            </div>
                        </div>}

                    {(row.parlour !== "E99" ? (row.numberMM && row.status === "accepted") : ((row.status === "accepted" || row.status === "new") && (row.numberMM || row.hasMmDocumentAttempt)))
                        &&
                        <div style={{
                            marginTop: 15
                        }}>
                            <Button onClick={() => {
                                if (!row.pickupNumber) {
                                    return SnackBar("W pierwszej kolejności wygeneruj list przewozowy.", "warning")
                                }

                                if (row.parlour !== "E99" && !row.numberMM) {
                                    return SnackBar("W pierwszej kolejności wygeneruj dokument MM-", "warning")
                                }

                                return Confirm(
                                    "Czy na pewno chcesz oznaczyć to zamówienie jako wysłane?",
                                    "",
                                    () => {
                                        api().post("/api/orderService/markSend", {
                                            orderId: row.order.id || row.orderId,
                                            parlour: row.parlour,
                                            id: row.id
                                        }).then((r) => {
                                            row.status = "send"

                                            this.setState({
                                                refresh: new Date().getTime()
                                            })

                                            this.props.tableRefresh();
                                        })
                                    })
                            }
                            }>{TranslationController.translate("Oznacz jako wysłane")}</Button>
                        </div>
                    }

                    {(!row.hasMmDocumentAttempt && row.mmErrorMessage) &&
                        <div>
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                background: "rgba(175,62,62,0.12)",
                                padding: 20,
                                borderRadius: 20,
                                marginBottom: 20,
                                marginTop: 20
                            }}>
                                <div style={{
                                    fontSize: 20,
                                    color: "#af3e3e"
                                }}>
                                    <Icon style={{color: "#af3e3e"}} className={"tm-main-icon"} name={"info"}/>
                                </div>

                                <div style={{
                                    color: "#af3e3e",
                                    fontWeight: 500,
                                    textAlign: "center"
                                }}>
                                    Ostatnia próba wygenerowania dokumentu MM zakończyła się niepowodzeniem:
                                </div>

                                <div style={{
                                    marginTop: 20,
                                    color: "#af3e3e",
                                    fontWeight: 700,
                                    textAlign: "center"
                                }}>
                                    {row.mmErrorMessage}
                                </div>
                            </div>
                        </div>
                    }
                </React.Fragment>
            </div>
        )
    }
}