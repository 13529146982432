import React from "react";
import {TranslationController} from "../../../controller/menager";
import {formatDate} from "../../../helpers/dateHelper";
import Button from "../../../components/Button";
import {DATA_OBJECT} from "../../../controller/dataController";
import {api, STATIC_URL} from "../../../lib/axios";
import SnackBar from "../../../components/Snack";
import Confirm from "../../../components/Confirm";

export default class OrderServicesCourierWidget extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            row: props.row
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.row !== this.props.row) {
            this.setState({row: this.props.row});
        }
    }

    render() {
        const row = this.state.row;

        return (
            <div>
                {row.pickupNumber &&
                    <div style={{
                        fontWeight: 600,
                        marginBottom: 15
                    }}>
                        <div style={{
                            fontWeight: 600,
                            color: "#848484"
                        }}>{TranslationController.translate("Numer listu przewozowego")}:</div>
                        {row.pickupNumber}
                    </div>
                }

                {row.pickupCreateDate &&
                    <div style={{
                        fontWeight: 600,
                        marginBottom: 15
                    }}>
                        <div style={{
                            fontWeight: 600,
                            color: "#848484"
                        }}>{TranslationController.translate("Data utworzenia")}:</div>
                        {formatDate(row.pickupCreateDate).dateTimeSystem}
                    </div>
                }

                {(row.pickupFile || row.pickupNumber) &&
                    <div style={{
                        marginTop: 15
                    }}>
                        <Button
                            color='blue'
                            content='List przewozowy'
                            icon='download'
                            label={{ basic: true, color: 'blue', pointing: 'left', content: '.pdf' }}
                            onClick={() => {
                                if (row.parlour.charAt(0) === "D") {
                                    window.open(row.pickupFile)
                                }
                                else if (process.env.REACT_APP_RUN_GLS_PICKUP === "true") {
                                    const format = ["K99", "E99"].includes(DATA_OBJECT.selected_parlour) ? 'smallPdf' : 'a4';
                                    api().get(`/api/orders/delivery/getLabel/${row.pickupNumber}/gls?format=${format}&parlour=${DATA_OBJECT.selected_parlour}&protocol=${row.protocol ?? ''}`).then(
                                        (result) => {
                                            if(result.data.error) {
                                                return SnackBar(result.data.error, "error")
                                            }
                                            const arrayBuffer = Uint8Array.from(result.data.label.data).buffer;
                                            const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
                                            const blobUrl = URL.createObjectURL(blob);
                                            window.open(blobUrl, "_blank")
                                        }
                                    )
                                }
                                else {
                                    window.open(`${STATIC_URL}${row.pickupFile}`)
                                }
                            }}
                        />
                    </div>
                }

                <React.Fragment>
                    {(row.parlour === "E99"
                            ? (!row.pickupFile && !row.pickupNumber)
                            : (row.status === "accepted" || row.status === "send") && (!row.pickupFile && !row.pickupNumber)) &&
                        <div style={{
                            marginTop: 15
                        }}>
                            <Button onClick={() => {
                                return Confirm(
                                    "Czy na pewno chcesz wygenerować nowy list przewozowy?",
                                    "",
                                    () => {
                                        this.props.toggleLoader(true);

                                        api().post("/api/orderService/generatePickup", {
                                            orderId: row.orderId,
                                            parlour: row.parlour,
                                            userId: DATA_OBJECT.user.login,
                                            id: row._id,
                                        }).then((r) => {
                                            this.props.toggleLoader(false);

                                            if (r.data.validation && r.data.validation.ValidationInfo && r.data.validation.ValidationInfo.length) {
                                                r.data.validation.ValidationInfo.forEach((e) => {
                                                    SnackBar(e.Info, "error")
                                                })
                                            } else {
                                                row.pickupNumber = r.data.data
                                                row.pickupFile = r.data.file
                                                row.pickupCreateDate = r.data.pickupCreateDate
                                                row.pickupSessionPackageId = r.data.pickupSessionPackageId

                                                if (row.parlour.charAt(0) === "D") {
                                                    window.open(r.data.file)
                                                } else if (process.env.REACT_APP_RUN_GLS_PICKUP === "true") {
                                                    const format = ["K99", "E99"].includes(DATA_OBJECT.selected_parlour) ? 'smallPdf' : 'a4';
                                                    api().get(`/api/orders/delivery/getLabel/${row.pickupNumber}/gls?format=${format}&parlour=${DATA_OBJECT.selected_parlour}`).then((r) => {
                                                        if(r.data.error) {
                                                            return SnackBar(r.data.error, "error")
                                                        }
                                                        const arrayBuffer = Uint8Array.from(r.data.label.data).buffer;

                                                        const blob = new Blob([arrayBuffer], {type: 'application/pdf'});
                                                        const blobUrl = URL.createObjectURL(blob);
                                                        window.open(blobUrl, "_blank")
                                                    })
                                                } else {
                                                    window.open(`${STATIC_URL}${r.data.file}`)
                                                }

                                                this.setState({
                                                    refresh: new Date().getTime(),
                                                })

                                                this.props.toggleLoader(false);
                                                this.props.tableRefresh();
                                            }
                                        })
                                    })
                            }
                            }>{TranslationController.translate("Generuj list przewozowy")}</Button>
                        </div>
                    }
                </React.Fragment>
            </div>
        )
    }
}