import React from 'react';
import EsotiqInput from '../../components/EsotiqInput';
import Container from '../../components/Container';
import Breadcrumb from "../../components/Breadcrumb";
import Paper from '@material-ui/core/Paper';
import UITable from '../../lib/table-v2';
import { Segment } from 'semantic-ui-react';
import PromotionCreatorBoxRow from '../promotions/promotionCreatorBoxRow';
import Button from '../../components/Button';
import SnackBar from '../../components/Snack';
import { exportToFile } from '../../controller/exportController';
import { api } from '../../lib/axios';

export default class Reservations extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sku: '',
            warehouseIds: '',
            searchParams: {
                sku: '',
                warehouseIds: []
            },
            tableKey: 0
        };

        this.handleSearch = this.handleSearch.bind(this);
        this.uniExport = this.uniExport.bind(this);
    }

    parseWarehouseIds(warehouseIds) {
        if (!warehouseIds) return [];
        
        return warehouseIds.split(',').map(id => id.trim());
    }

    handleSearch() {
        if (!this.validateSearch()) return;
        this.setState({
            searchParams: {
                sku: this.state.sku,
                warehouseIds: this.parseWarehouseIds(this.state.warehouseIds)
            },
            tableKey: this.state.tableKey + 1
        });

        if (this.table) {
            this.table.setState({
                data: null
            });

            this.table.init();
        }
    }

    validateSearch() {
        const warehouseIdsRegexp = new RegExp('^\\w+(,{1}\\w+)*$')
        if (!this.state.sku) {
            return SnackBar("SKU jest wymagane", "error");
        }

        if (this.state.warehouseIds && !warehouseIdsRegexp.test(this.state.warehouseIds)) {
            return SnackBar("Niepoprawny format magazynów", "error");
        }

        return true;
    }

    uniExport() {
        if (!this.validateSearch()) return;

        api().post("/api/reservations/active", {
            sku: this.state.searchParams.sku,
            warehousesId: this.state.searchParams.warehouseIds,
            currentPage: 1,
            limit: 10000
        })
            .then(r => {
                return exportToFile(
                    r.data.data,
                    "uniExport",
                    ['createdAt', 'domainId', 'productSku', 'slug', 'warehouseId'],
                )
            })
    }

    render() {
        return (
            <Container>
                <Breadcrumb
                    crumbs={[
                        {
                            label: `Aktywne rezerwacje`
                        }
                    ]}
                />

                <div style={{height: 10}}></div>

                <Paper style={{padding: 20}}>
                    <PromotionCreatorBoxRow
                        label="SKU"
                        content={() => (
                            <EsotiqInput
                                placeholder=""
                                onChange={(v) => this.setState({sku: v})}
                            />
                        )}
                    />
                    
                    <PromotionCreatorBoxRow
                        label="Magazyny (po przecinku)"
                        content={() => (
                            <EsotiqInput
                                placeholder=""
                                onChange={(v) => this.setState({warehouseIds: v})}
                            />
                        )}
                    />

                    <PromotionCreatorBoxRow
                        label="Akcje"
                        content={() => (
                            <>
                                <Button onClick={this.handleSearch}>Szukaj</Button>
                                &nbsp;
                                <Button onClick={this.uniExport}>Eksportuj do CSV</Button>
                            </>
                        )}
                    />

                    {this.state.searchParams.sku && (
                        <Segment className={"order-table-segment"} attached='bottom'>
                            <UITable 
                                key={this.state.tableKey}
                                dataFetch={
                                    {
                                        url: '/api/reservations/active',
                                        options: {
                                            currentPage: 1,
                                            limit: 25,
                                            sku: this.state.searchParams.sku,
                                            warehousesId: this.state.searchParams.warehouseIds
                                        }
                                    }
                                }
                                ref={table => this.table = table}
                                provider={this}
                                
                                columns={[{
                                    label: "Data utworzenia",
                                    field: "createdAt",
                                }, {
                                    label: "Numer zamówienia",
                                    field: "domainId",
                                }, {
                                    label: "SKU",
                                    field: "productSku",
                                }, {
                                    label: "Slug",
                                    field: "slug",
                                }, {
                                    label: "Magazyn",
                                    field: "warehouseId"
                                }]}
                            />
                        </Segment>
                    )}
                </Paper>
            </Container>
        );
    }
}