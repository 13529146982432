import React from "react"
import {Modal, Table, Message, Icon} from "semantic-ui-react";
import Button from "../../components/Button";
import {formatDate} from "../../helpers/dateHelper";
import {DATA_OBJECT} from "../../controller/dataController";

export default class OnlineComplaintRevertSummary extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            revertBankName: null,
            revertBankAccount: null,
            revertModal: false,
        }
    }

    send() {
        if(this.props.data.revertData[0].revertType === 'revert') {
            (DATA_OBJECT.selected_parlour === 'VOS' || DATA_OBJECT.user_login === 'root') ? this.setState({revertModal: true}) : this.props.setComplaint()
        } else {
            this.props.setComplaint()
        }
    }

    getRevertPrice() {
        let price = 0;

        this.props.data.revertData.forEach(item => {
            price += parseFloat(item.revertProduct.finalQtyPrice)
        })

        return price;
    }

    render() {
        return (
            <div style={{
                width: 600
            }}>
                <Message
                    header={"Potwierdzenie zwrotu"}
                    content={"Zamówienie nr " + this.props.data.orderData[0].id}
                    info
                />

                <div style={{marginBottom: 20}}></div>

                <div>
                    <div className={"revert-summary-wrapper"}>
                        <div className={"revert-summary-title"}>Informacje o zamówieniu</div>

                        <div className={"revert-summary-info-wrapper"}>
                            <div className={"revert-summary-box"}>
                                <div className={"revert-summary-label"}>Data zamówienia:</div>
                                <div className={"revert-summary-value"}>{formatDate(this.props.data.orderData[0].createdAt).dateTimeSystem}</div>
                            </div>

                            <div className={"revert-summary-box"}>
                                <div className={"revert-summary-label"}>Zamawiający:</div>
                                <div className={"revert-summary-value"}>{this.props.data.orderData[0].addressShipping.firstname}</div>
                            </div>

                            <div className={"revert-summary-box"}>
                                <div className={"revert-summary-label"}>Forma zwrotu:</div>
                                <div className={"revert-summary-value"}>{this.props.data.revertData[0].revertType === "complaint" ? "Reklamacja" : "Zwrot"}</div>
                            </div>

                            <div className={"revert-summary-box"}>
                                <div className={"revert-summary-label"}>Wartość zwrotu:</div>
                                <div className={"revert-summary-value"}>{parseInt(this.getRevertPrice()).toFixed(2)} {this.props.data.orderData[0].currency}</div>
                            </div>
                        </div>
                    </div>

                    <div className={"revert-summary-wrapper"}>
                        <div className={"revert-summary-title"}>Dane do zwrotu</div>

                        <div className={"revert-summary-box"}>
                            <div className={"revert-summary-label"}>Bank:</div>
                            <div className={"revert-summary-value"}>{this.props.data.revertCustomerData ? this.props.data.revertCustomerData.revertBankName || "---" : "---"}</div>
                        </div>

                        <div className={"revert-summary-box"}>
                            <div className={"revert-summary-label"}>Numer konta:</div>
                            <div className={"revert-summary-value"}>{this.props.data.revertCustomerData ? this.props.data.revertCustomerData.revertBankAccount || "---" : "---"}</div>
                        </div>
                    </div>

                    <div className={"revert-summary-wrapper"}>
                        <div className={"revert-summary-title"}>Towary zwracane w ramach tego zamówienia</div>

                        <div>
                            <Table celled>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Produkt</Table.HeaderCell>
                                        <Table.HeaderCell></Table.HeaderCell>
                                        <Table.HeaderCell>Il. zwracanych szt.</Table.HeaderCell>
                                        <Table.HeaderCell>{this.props.data.revertData[0].revertType === "complaint" ? "Forma reklamacji" : "Powód zwrotu"}</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {this.props.data.revertData.map((revertData, key) => {
                                        return (
                                            <Table.Row key={key}>
                                                <Table.Cell width={4}>
                                                    <div style={{
                                                        fontWeight: 600
                                                    }}>
                                                        {revertData.name}
                                                    </div>
                                                    <div style={{
                                                        fontWeight: 600,
                                                        fontSize: "0.9em",
                                                        color: "#8f8f8f"
                                                    }}>
                                                        {revertData.sku}
                                                    </div>
                                                </Table.Cell>

                                                <Table.Cell width={3}>
                                                    <div style={{
                                                        cursor: "pointer"
                                                    }} onClick={() => {
                                                        if (!revertData.revertProduct.images) return false

                                                        this.setState({
                                                            imageModal: `${revertData.revertProduct.images[0].file}`
                                                        })
                                                    }}>
                                                        {revertData.revertProduct.images ? <img style={{
                                                            width: 60
                                                        }} src={`${revertData.revertProduct.images[0].file}`} alt=""/> : "---"}
                                                    </div>
                                                </Table.Cell>

                                                <Table.Cell>
                                                    {revertData.revertQty}
                                                </Table.Cell>

                                                <Table.Cell>
                                            <span style={{
                                                fontSize: "0.9em",
                                                color: "#676767"
                                            }}>
                                                {revertData.revertType === "complaint" ? (revertData.complaintForm || "---") : (revertData.revertReason || "---")}
                                            </span>

                                                    <div style={{
                                                        fontSize: "0.9em",
                                                        color: "#676767",
                                                        margin: "10px 0"
                                                    }}>Dodatkowe informacje:</div>

                                                    <div style={{
                                                        fontSize: "0.9em",
                                                        color: "#676767"
                                                    }}>
                                                        {revertData.revertAdditionalInfo || "---"}
                                                    </div>
                                                </Table.Cell>
                                            </Table.Row>
                                        )
                                    })}
                                </Table.Body>
                            </Table>
                        </div>
                    </div>

                    <div style={{
                        marginTop: 15,
                        display: "flex",
                        justifyContent: "flex-end"
                    }}>
                        <Button
                            primary={true}
                            onClick={() => this.send()}
                        >
                            Potwierdzam złożenie zwrotu/reklamacji
                        </Button>
                    </div>
                </div>

                {this.state.imageModal &&
                    <Modal dimmer={"inverted"} size={"medium"} open={this.state.imageModal} onClose={() => this.setState({imageModal: false})}>
                        <Modal.Header></Modal.Header>
                        <Modal.Content>
                            <div>
                                <img style={{
                                    maxWidth: 500,
                                    margin: "0 auto",
                                    display: "block"
                                }} src={this.state.imageModal} alt=""/>
                            </div>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => this.setState({imageModal: false})} negative>Zamknij</Button>
                        </Modal.Actions>
                    </Modal>
                }
                {this.state.revertModal &&
                    <Modal dimmer={"inverted"}
                           size={"small"}
                           open={this.state.revertModal}
                           onClose={() => this.setState({revertModal: false})}>
                        <Modal.Header>Zamierzasz dokonać zwrotu zamówienia nr {this.props.data.orderData[0].id}</Modal.Header>
                        <Modal.Content>
                            <div style={{
                                color: "red"
                            }}>
                                Upewnij się, że wprowadzone towary są poprawne. Pamiętaj, że potwierdzenie zwrotu przesunie towar na stan salonu.
                            </div>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => this.setState({revertModal: false})} negative>Anuluj</Button>
                            <Button onClick={() => {
                                this.props.setComplaint()
                                this.setState({revertModal: false})
                            }} positive>Potwierdź</Button>
                        </Modal.Actions>
                    </Modal>
                }

            </div>
        );
    }
}
