import React from 'react';
import { Page, Text, View, Document, StyleSheet, PDFViewer, PDFDownloadLink, Font, Image } from '@react-pdf/renderer';
import {
    Step,
    Icon,
    Radio,
    Input,
    Table,
    Checkbox,
    Confirm as SemanticConfirm,
    Dimmer,
    Loader,
    Icon as SemanticIcon
} from "semantic-ui-react";
import {formatDate} from "../../helpers/dateHelper";
import SnackBar from "../../components/Snack";
import OrderServiceEANScan from "./orderServiceEanScan";
import {api, STATIC_URL} from "../../lib/axios";
import { useBarcode } from '@createnextapp/react-barcode';
import Button from "../../components/Button";
import {TranslationController} from "../../controller/menager";
import {DATA_OBJECT} from "../../controller/dataController";
import Confirm from "../../components/Confirm";
import WarehouseOrderServiceEANScan from "./orderServiceEanScan";
import {copyToClipboard} from "../../lib/globals";

const sourceLight = require("../../resources/fonts/OpenSans-Light.ttf")
const sourceRegular = require("../../resources/fonts/OpenSans-Regular.ttf")

Font.register({ family: 'OpenSansLight', src: sourceLight });
Font.register({ family: 'OpenSans', src: sourceRegular });

function BarCode(props) {
    const { inputRef } = useBarcode({
        value: props.id,
        options: {
            height: 25
        }
    });

    return <img id={props.id} ref={inputRef}/>
}

// Create styles
const styles = StyleSheet.create({
    page: {
        backgroundColor: '#fff',
        padding: "40px 20px",
        fontFamily: 'OpenSans'
    },
    section: {
        margin: 10,
        padding: 10,
    },
    title: {
        color: "#545454",
        fontSize: 24,
        fontWeight: 700,
    },
    subinfo: {
        fontSize: 10,
        textAlign: "right",
        marginTop: 5,
        fontFamily: 'OpenSansLight'
    },
    contactBox: {
        marginTop: 10,
        borderBottomWidth: 1,
        borderColor: "#000",
        paddingBottom: 5
    },
    subinfoLeft: {
        fontSize: 10,
        marginTop: 5,
        fontFamily: 'OpenSansLight'
    },
    tableBox: {
        margin: "10px 0"
    },
    tableHeader: {
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%"
    },
    tableHeaderCol: {
        padding: 3,
        borderWidth: 1,
        borderBottomWidth: 0,
        flex: 1,
    },
    tableHeaderColNoWidth: {
        padding: 3,
        borderWidth: 1,
    },
    tableHeaderColText: {
        fontSize: 10,
        textAlign: "center",
    },
    tableBody: {
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%"
    },
    tableBodyCol: {
        padding: 3,
        borderWidth: 1,
        flex: 1,
    },
    tableBodyColText: {
        fontSize: 10,
        textAlign: "center",
    },
    tableBodyColNoWidth: {
        padding: 3,
        borderWidth: 1,
    },
    tableBoxEnd: {
        width: "50%",
        marginTop: 5
    },
    tableBodyColCustom: {
        padding: 3,
        borderWidth: 1,
        flex: 1,
        height: 50
    },
    finalText: {
        textAlign: "center",
        marginTop: 10,
        fontSize: 9,
        fontFamily: 'OpenSansLight'
    },
    finalBox: {
        marginTop: 0
    }
});

// Create Document Component
class OrderPackingPDFInternal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            productPages: []
        }
    }

    componentDidMount() {
        const chunks = [];

        const chunkSize = 18;
        for (let i = 0; i < this.props.data.sending.length; i += chunkSize) {
            const chunk = this.props.data.sending.slice(i, i + chunkSize);

            chunks.push(chunk);
        }

        this.setState({
            productPages: chunks
        })
    }

    render() {
        const {barcode} = this.props;

        let isExternalOrder = false;
        let isDEOrder = false;

        if (this.props.data && this.props.data.order) {
            isExternalOrder = (this.props.data.order.id ?? this.props.data.orderId).toString().charAt(0) === "8";
            isDEOrder = (this.props.data.order.id ?? this.props.data.orderId).toString().charAt(0) === "4";
        }

        return (
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={{
                        paddingBottom: 3,
                        borderBottomWidth: 3,
                        borderColor: "#9d9d9d",
                        width: "100%",
                        flexDirection: "row",
                        justifyContent: "space-between"
                    }}>
                        <View style={{
                            width: "35%",
                        }}>
                            <Image src={barcode}/>
                        </View>
                        <View>
                            <Text style={styles.title}>{TranslationController.translate("Specyfikacja towarowa")}</Text>
                        </View>
                    </View>

                    <View>
                        <Text style={styles.subinfo}>{formatDate().dateNow}</Text>
                        <Text style={styles.subinfo}>{TranslationController.translate("Salon")}: {this.props.data && this.props.data.parlour}</Text>
                    </View>

                    <View style={styles.contactBox}>
                        <Text style={styles.subinfoLeft}>{TranslationController.translate("Biuro Obsługi Klienta Pn-Pt 7:30-15:30")}</Text>
                        <Text style={styles.subinfoLeft}>{TranslationController.translate("kontakt@esotiq.com tel. 799 352 880")}</Text>
                    </View>

                    <View style={styles.tableBox}>
                        <View style={styles.tableHeader}>
                            <View style={styles.tableHeaderCol}>
                                <Text style={styles.tableHeaderColText}>{TranslationController.translate("Numer zamówienia")}</Text>
                            </View>
                            <View style={{...styles.tableHeaderCol, ...{borderRightWidth: 0, borderLeftWidth: 0}}}>
                                <Text style={styles.tableHeaderColText}>{TranslationController.translate("Data zamówienia")}</Text>
                            </View>
                            <View style={styles.tableHeaderCol}>
                                <Text style={styles.tableHeaderColText}>{TranslationController.translate("Forma wysyłki")}</Text>
                            </View>
                        </View>
                        <View style={styles.tableBody}>
                            <View style={styles.tableBodyCol}>
                                <Text style={styles.tableBodyColText}>{this.props.data && (this.props.data.order.id ?? this.props.data.orderId)}</Text>
                            </View>
                            <View style={{...styles.tableBodyCol, ...{borderRightWidth: 0, borderLeftWidth: 0}}}>
                                <Text style={styles.tableBodyColText}>---</Text>
                            </View>
                            <View style={styles.tableBodyCol}>
                                <Text style={styles.tableBodyColText}>---</Text>
                            </View>
                        </View>
                    </View>

                    <View>
                        <Text style={{
                            fontSize: 10,
                            fontWeight: 600,
                        }}>
                            {isDEOrder ? TranslationController.translate("Przesunięcie do D99") : TranslationController.translate("Przesunięcie do E99")}
                        </Text>
                    </View>

                    <View style={styles.tableBox}>
                        <View style={styles.tableHeader}>
                            <View style={{...styles.tableHeaderColNoWidth, ...{width: "13%", borderBottomWidth: 0}}}>
                                <Text style={styles.tableHeaderColText}>{TranslationController.translate("Spakowano")}</Text>
                            </View>
                            <View style={{...styles.tableHeaderColNoWidth, ...{width: "27%", borderRightWidth: 0, borderLeftWidth: 0, borderBottomWidth: 0}}}>
                                <Text style={styles.tableHeaderColText}>{TranslationController.translate("SKU")}</Text>
                            </View>
                            <View style={{...styles.tableHeaderColNoWidth, ...{width: "10%", borderRightWidth: 0, borderBottomWidth: 0}}}>
                                <Text style={styles.tableHeaderColText}>{TranslationController.translate("Ilość")}</Text>
                            </View>
                            <View style={{...styles.tableHeaderColNoWidth, ...{width: "50%", borderBottomWidth: 0}}}>
                                <Text style={styles.tableHeaderColText}>{TranslationController.translate("Nazwa")}</Text>
                            </View>
                        </View>
                        {this.state.productPages && this.state.productPages[0] && this.state.productPages[0].map((e, k) => {
                            return (
                                <View key={k} style={styles.tableBody}>
                                    <View style={{...styles.tableBodyColNoWidth, ...{width: "13%"}}}>
                                    </View>
                                    <View style={{...styles.tableBodyColNoWidth, ...{width: "27%",  borderRightWidth: 0, borderLeftWidth: 0}}}>
                                        <Text style={styles.tableBodyColText}>{e.sku}</Text>
                                    </View>
                                    <View style={{...styles.tableBodyColNoWidth, ...{width: "10%", borderRightWidth: 0}}}>
                                        <Text style={styles.tableBodyColText}>{e.qty}</Text>
                                    </View>
                                    <View style={{...styles.tableBodyColNoWidth, ...{width: "50%"}}}>
                                        <Text style={styles.tableBodyColText}>{DATA_OBJECT["user"] && (DATA_OBJECT["user"]["country"] === "pl" ? e.name : (DATA_OBJECT["user"]["country"] === "de" && e.deName) ? e.deName : e.sku)}</Text>
                                    </View>
                                </View>
                            )
                        })}
                    </View>

                    {this.state.productPages && (this.state.productPages.length === 1) &&
                        <View>
                            <View style={styles.tableBoxEnd}>
                                <View style={styles.tableHeader}>
                                    <View style={styles.tableHeaderCol}>
                                        <Text style={styles.tableHeaderColText}>{TranslationController.translate("Spakował (podpis)")}</Text>
                                    </View>
                                </View>
                                <View style={styles.tableBody}>
                                    <View style={styles.tableBodyColCustom}>
                                    </View>
                                </View>
                            </View>

                            <View style={styles.finalBox}>
                                <Text style={styles.finalText}>{TranslationController.translate("Dziękujemy za zakupy")}</Text>
                                <Text style={styles.finalText}>{TranslationController.translate("Proszę o zgłoszenie niezgodności w dostawie niezwłocznie adres mailowy biura obsługi klienta kontakt@esotiq.com podając numer zamówienia w tytule wiadomości.")}</Text>
                            </View>
                        </View>
                    }
                </Page>

                {this.state.productPages && this.state.productPages.length > 1 &&
                    this.state.productPages.map((page, index, self) => {
                        if (index === 0) return null;

                        return (
                            <Page size="A4" style={styles.page}>
                                {page.map((e, k) => (
                                    <View key={k} style={styles.tableBody}>
                                        <View style={{...styles.tableBodyColNoWidth, ...{width: "13%"}}}>
                                        </View>
                                        <View style={{...styles.tableBodyColNoWidth, ...{width: "27%",  borderRightWidth: 0, borderLeftWidth: 0}}}>
                                            <Text style={styles.tableBodyColText}>{e.sku}</Text>
                                        </View>
                                        <View style={{...styles.tableBodyColNoWidth, ...{width: "10%", borderRightWidth: 0}}}>
                                            <Text style={styles.tableBodyColText}>{e.qty}</Text>
                                        </View>
                                        <View style={{...styles.tableBodyColNoWidth, ...{width: "50%"}}}>
                                            <Text style={styles.tableBodyColText}>{(DATA_OBJECT["user"] && DATA_OBJECT["user"]["country"] === "pl") ? e.name : e.sku}</Text>
                                        </View>
                                    </View>
                                ))}

                                {index === self.length - 1 &&
                                    <View>
                                        <View style={styles.tableBoxEnd}>
                                            <View style={styles.tableHeader}>
                                                <View style={styles.tableHeaderCol}>
                                                    <Text style={styles.tableHeaderColText}>{TranslationController.translate("Spakował (podpis)")}</Text>
                                                </View>
                                            </View>
                                            <View style={styles.tableBody}>
                                                <View style={styles.tableBodyColCustom}>
                                                </View>
                                            </View>
                                        </View>

                                        <View style={styles.finalBox}>
                                            <Text style={styles.finalText}>{TranslationController.translate("Dziękujemy za zakupy")}</Text>
                                            <Text style={styles.finalText}>{TranslationController.translate("Proszę o zgłoszenie niezgodności w dostawie niezwłocznie adres mailowy biura obsługi klienta kontakt@esotiq.com podając numer zamówienia w tytule wiadomości.")}</Text>
                                        </View>
                                    </View>
                                }
                            </Page>
                        )
                    })
                }
            </Document>
        );
    }
}

export default class OrderPackingPDFDocumentInternal extends React.Component {
    constructor(props) {
        super(props);

        this.downloadLink = React.createRef()

        this.state = {
            completedStep: this.props.parlour === "K99" ? 1 : 0,
            rejected: {},
            rejectedItems: [],
            accepted: null,
            orderScanningController: null
        }
    }

    componentDidMount() {
        if (this.props.previouslyScanned) {
            this.setState({
                accepted: this.props.data.sending
            })
        }
    }

    reject() {
        const rejectedItems = []

        Object.keys(this.state.rejected)
            .forEach(sku => {
                rejectedItems.push(this.state.rejected[sku])
            })

        this.state.rejectedItems = rejectedItems;
        this.forceUpdate();
    }

    completeStep(step) {
        if (step < this.state.completedStep) {
            return;
        }

        if (step === "1") {
            api().post("/api/orderService/update", {
                id: this.props.data._id,
                orderId: this.props.orderId,
                parlour: this.props.parlour,
                update: {
                    hasPdf: true
                }
            })
        }

        if (step === "2") {
            if (this.state.completedStep < 1) {
                SnackBar("Najpierw pobierz list pakowy i sprawdź stan towarowy.", "error")
                return false
            }
        }

        this.state.completedStep = parseInt(step);
        this.forceUpdate();

        if (step === "2") {
            this.state.orderScanningController.checkSuccess();
        }
    }

    resetPreviouslyScanned() {
        this.props.reset();

        api().post("/api/orderService/resetScan", {
            id: this.props.data._id,
            orderId: this.props.orderId,
            parlour: this.props.parlour,
        })
            .then(r => {
                this.props.recallData()
            })
    }

    acceptOrder() {
        const parlourData = this.props.data

        parlourData.sending = parlourData.sending.map(item => ({...item, ...{status: "accepted"}}));

        parlourData.status = "accepted";

        // parlourData.statusHistory.push({
        //     status: "accepted",
        // })

        parlourData.acceptedTime = formatDate().dateNowSystem;

        api().post("/api/orderService/accept", {
            orderId: this.props.orderId,
            parlour: this.props.parlour,
            orderService: parlourData,
            internal: true
        })
            .then(r => {
                if (r.data && r.data.error) {
                    this.setState({
                        dimmer:false
                    })

                    return SnackBar(r.data.message, "error")
                }

                window.open("#/warehouse/shipping-internal", "_self")
            })
    }

    rejectOrder() {
        const rejectedData = this.state.rejected;
        const parlourData = this.props.data;

        Object.keys(rejectedData)
            .forEach(rejectedSku => {
                parlourData.sending.map(item => {
                    if (item.sku === rejectedSku) {
                        item.status = "rejected"
                        item.statusHistory.push({
                            status: "rejected",
                        })
                        item.rejectedCount = parseInt(rejectedData[rejectedSku].qty);

                        if (item.rejectedCount > parseInt(rejectedData[rejectedSku].qty)) {
                            item.rejectedCount = parseInt(rejectedData[rejectedSku].qty)
                        }
                    } else {
                        if (item.status !== "rejected") {
                            item.statusHistory.push({
                                status: "canceled",
                            });

                            item.status = "canceled"
                        }
                    }
                })
            });

        parlourData.status = "rejected"

        parlourData.rejectedTime = formatDate().dateNowSystem

        api().post("/api/orderService/reject", {
            orderId: this.props.orderId,
            parlour: this.props.parlour,
            orderService: parlourData,
            userId: DATA_OBJECT["user"]["login"]
        })
            .then(r => {
                if (r.data && r.data.error) {
                    this.setState({
                        dimmer:false
                    })

                    return SnackBar(r.data.message, "error")
                }

                if (this.props.orderId.toString().charAt(0) === "8") {
                    window.open("#/warehouse/shipping-internal", "_self")
                } else {
                    window.open("#/warehouse/shipping-internal", "_self")
                }
            })
    }

    render() {
        let isExternalOrder = this.props.orderId.toString().charAt(0) === "8";
        let isDEOrder = this.props.orderId.toString().charAt(0) === "4";
        const year = new Date().getFullYear();

        return (
            <React.Fragment>
                <div style={{
                    width: 800,
                    display: "block",
                    margin: "0 auto",
                    marginBottom: 10
                }}>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between"
                    }}>
                        <Button
                            onClick={() => {
                                window.open("#/warehouse/shipping-internal", '_self')
                            }}
                        >
                            {TranslationController.translate("Powrót")}
                        </Button>

                        <div style={{
                            fontWeight: 600,
                            color: "#767676",
                            position: "relative",
                            top: 5
                        }}>{isExternalOrder ? TranslationController.translate("Przesunięcie do K99") : isDEOrder ? TranslationController.translate("Przesunięcie do D99") : TranslationController.translate("Przesunięcie do X99")}</div>

                        <Button
                            negative
                            onClick={() => {
                                Confirm(TranslationController.translate("Czy na pewno chcesz odrzucić całe zamówienie?"), null, () => {
                                    this.state.rejected = {};
                                    this.props.data.sending.forEach((e) => {
                                        if (!this.state.rejected[e.sku]) {
                                            this.state.rejected[e.sku] = {qty: 0}
                                        }

                                        this.state.rejected[e.sku].qty += parseInt(e.qty);
                                    })

                                    this.rejectOrder();
                                })
                            }}
                        >
                            {TranslationController.translate("Odrzuć całe zamówienie")}
                        </Button>
                    </div>
                </div>

                <div style={{width: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <div style={{
                        display: "none",
                        position: "absolute"
                    }}>
                        <BarCode id={this.props.orderId}/>
                    </div>

                    <div style={{
                        margin: "0 auto",
                        display: "flex",
                        justifyContent: "center",
                    }}>
                        <Step.Group style={{
                            width: 800
                        }} vertical>
                            {!(this.props.parlour === "K99") &&
                                <Step active={this.props.previouslyScanned ? false : this.state.completedStep === 0} completed={this.props.previouslyScanned ? true : this.state.completedStep >= 1}>
                                    <Icon name='cloud download' />
                                    <Step.Content>
                                        <Step.Title>{TranslationController.translate("List pakowy")}</Step.Title>
                                        <Step.Description>{TranslationController.translate("Pobierz dokument w PDF")}</Step.Description>

                                        <div style={{marginTop: 5}}></div>

                                        {this.state.generatedPdf ?
                                            <PDFDownloadLink
                                                ref={e => this.downloadLink = e}
                                                document={
                                                    <OrderPackingPDFInternal
                                                        data={this.props.data}
                                                        fileName={`łist_pakowy_${(this.props.data.order.id ?? this.props.data.orderId)}`}
                                                        barcode={document.getElementById(this.props.orderId).src}
                                                    />
                                                }
                                            >
                                                {({ blob, url, loading, error }) => {
                                                    if (error) {
                                                        console.log(error, "Error while pdf generate")
                                                    }

                                                    if (!loading && url) {
                                                        this.state.generatedPdf = false;

                                                        if (this.props.pdfOnly) {
                                                            setTimeout(() => {
                                                                window.open("#/warehouse/shipping-internal", "_self")
                                                            }, 500)
                                                        } else {
                                                            this.completeStep("1")
                                                        }

                                                        window.open(url, '_blank')
                                                    }

                                                    return (loading ? <Button
                                                        color='blue'
                                                        loading={true}
                                                        label={{ basic: true, color: 'blue', pointing: 'left', content: '.pdf' }}
                                                    /> : <Button
                                                        color='blue'
                                                        content='Pobierz'
                                                        icon='download'
                                                        label={{ basic: true, color: 'blue', pointing: 'left', content: '.pdf' }}
                                                        onClick={(e) => {
                                                            e.preventDefault();

                                                            if (this.props.pdfOnly) {
                                                                setTimeout(() => {
                                                                    window.open("#/warehouse/shipping-internal", "_self")
                                                                }, 500)
                                                            } else {
                                                                this.completeStep("1")
                                                            }
                                                            window.open(url, '_blank')
                                                        }}
                                                    />)
                                                }}
                                            </PDFDownloadLink> : <Button
                                                color='blue'
                                                content='Generuj'
                                                icon='download'
                                                label={{ basic: true, color: 'blue', pointing: 'left', content: '.pdf' }}
                                                onClick={() => this.setState({
                                                    generatedPdf: true
                                                })}
                                            />}
                                    </Step.Content>
                                </Step>
                            }

                            {!this.props.pdfOnly &&
                                <React.Fragment>
                                    <Step
                                        disabled={this.props.previouslyScanned ? false : this.state.completedStep < 1}
                                        active={this.state.completedStep === 1}
                                        completed={this.props.previouslyScanned ? true : this.state.completedStep >= 2}
                                    >
                                        <Icon name='calendar check outline' />
                                        <Step.Content>
                                            <Step.Title>{TranslationController.translate("Sprawdź stan towarowy")}</Step.Title>
                                            <Step.Description>{TranslationController.translate("Dokładnie upewnij się, że faktycznie posiadasz towary na stanie")}</Step.Description>

                                            <div style={{marginTop: 5}}>
                                                <div style={{
                                                    marginBottom: 5,
                                                    marginTop: 5
                                                }}>
                                                    <Radio
                                                        name={"stateCheckin"}
                                                        value={"x"}
                                                        disabled={this.props.previouslyScanned ? false : this.state.completedStep < 1}
                                                        checked={this.state.imitCheckbox ? this.state.imitCheckbox === 'this' : this.props.previouslyScanned}
                                                        onChange={() => {
                                                            this.setState({
                                                                imitCheckbox: "this",
                                                                rejectedTable: false,
                                                                rejected: {},
                                                                rejectedItems: []
                                                            });
                                                            this.completeStep("2");

                                                            document.getElementById("scan-input").focus()
                                                        }}
                                                        label={TranslationController.translate("Tak, posiadam całe zamówienie na stanie")}
                                                    />
                                                </div>
                                                <div>
                                                    <Radio
                                                        name={"stateCheckin"}
                                                        value={"y"}
                                                        disabled={this.props.previouslyScanned ? false : this.state.completedStep < 1}
                                                        checked={this.state.imitCheckbox === 'that'}
                                                        onChange={() => {
                                                            this.setState({
                                                                rejectedTable: true,
                                                                imitCheckbox: "that",
                                                                completedStep: 1,
                                                                givenMM: null
                                                            });

                                                            if (this.state.orderScanningController) {
                                                                this.state.orderScanningController.reset();
                                                            }

                                                            if (this.props.previouslyScanned) {
                                                                this.resetPreviouslyScanned()
                                                            }
                                                        }}
                                                        label={TranslationController.translate("Nie, brakuje niektórych pozycji")}
                                                    />
                                                </div>

                                                {this.state.rejectedTable &&
                                                    <div className={"rejectedTable"} style={{marginTop: 10}}>
                                                        <Table celled>
                                                            <Table.Header>
                                                                <Table.Row>
                                                                    <Table.HeaderCell></Table.HeaderCell>
                                                                    <Table.HeaderCell>{TranslationController.translate("SKU")}</Table.HeaderCell>
                                                                    <Table.HeaderCell>{TranslationController.translate("Nazwa")}</Table.HeaderCell>
                                                                    <Table.HeaderCell>{TranslationController.translate("Ilość")}</Table.HeaderCell>
                                                                </Table.Row>
                                                            </Table.Header>

                                                            <Table.Body>
                                                                {this.props.data.sending.map((item, key) => {
                                                                    return (
                                                                        <Table.Row>
                                                                            <Table.Cell>
                                                                                <Checkbox
                                                                                    disabled={!this.state.rejected[item.sku]}
                                                                                    onClick={() => {
                                                                                        if (!this.state.rejected[item.sku]) {
                                                                                            return SnackBar("Wprowadź ilość brakującego towaru.", "error")
                                                                                        }

                                                                                        this.reject()
                                                                                    }}
                                                                                    onChange={() => {
                                                                                    }}
                                                                                />
                                                                            </Table.Cell>
                                                                            <Table.Cell>{item.sku}</Table.Cell>
                                                                            <Table.Cell>{TranslationController.translateProduct(item.name)}</Table.Cell>
                                                                            <Table.Cell>
                                                                                <Input
                                                                                    label={{ basic: true, content: `z ${item.qty}` }}
                                                                                    labelPosition='right'
                                                                                    placeholder='Szt.'
                                                                                    onChange={(e, v) => {
                                                                                        const {rejected} = this.state

                                                                                        if (!v.value.length) {
                                                                                            delete rejected[item.sku]

                                                                                            return this.setState({
                                                                                                rejected
                                                                                            })
                                                                                        }

                                                                                        if (v.value > item.qty) {
                                                                                            delete rejected[item.sku]

                                                                                            this.setState({
                                                                                                rejected
                                                                                            })
                                                                                            return SnackBar("Wprowadzona ilość brakującego towaru jest wyższa niż faktyczny stan zamówienia.", "error")
                                                                                        } else {
                                                                                            rejected[item.sku] = {
                                                                                                qty: v.value
                                                                                            }

                                                                                            this.setState({
                                                                                                rejected
                                                                                            })
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </Table.Cell>
                                                                        </Table.Row>
                                                                    )
                                                                })}
                                                            </Table.Body>
                                                        </Table>

                                                        <Button
                                                            onClick={() => {
                                                                this.setState({
                                                                    confirm: true
                                                                })
                                                            }}
                                                            color={"blue"}
                                                            icon
                                                            labelPosition='right'
                                                            disabled={!this.state.rejectedItems.length}
                                                        >
                                                            {TranslationController.translate("Zgłoś brak wybranych towarów i odrzuć zamówienie")}
                                                            <Icon name='checkmark' />
                                                        </Button>

                                                        <SemanticConfirm
                                                            dimmer={"inverted"}
                                                            open={this.state.confirm}
                                                            content={'Czy na pewno chcesz odrzucić zamówienie?'}
                                                            onCancel={() => this.setState({confirm: false})}
                                                            onConfirm={() => {
                                                                this.setState({confirm: false, dimmer: true});
                                                                this.rejectOrder();
                                                            }}
                                                        />
                                                    </div>
                                                }
                                            </div>
                                        </Step.Content>
                                    </Step>

                                    <Step
                                        disabled={this.props.previouslyScanned ? false : this.state.completedStep < 2}
                                        active={this.props.previouslyScanned ? true : this.state.completedStep === 2}
                                        completed={this.state.completedStep >= 3}
                                    >
                                        <Icon name='barcode' />
                                        <Step.Content style={{width: "92%"}}>
                                            <Step.Title>{TranslationController.translate("Zeskanuj kody kreskowe")}</Step.Title>
                                            <OrderServiceEANScan
                                                parlour={this.props.parlour}
                                                orderId={this.props.orderId}
                                                orderServiceId={this.props.data._id}
                                                disabled={this.props.previouslyScanned ? false : this.state.completedStep < 2}
                                                sending={this.props.data.sending}
                                                setOrderScanningController={(controller) => this.state.orderScanningController = controller}
                                                onSuccess={() => {
                                                    this.setState({
                                                        accepted: this.props.data.sending
                                                    })

                                                    this.completeStep("3")
                                                }}
                                                onRejected={() => {
                                                    this.setState({
                                                        rejectedTable: true,
                                                        imitCheckbox: "that",
                                                        completedStep: 1,
                                                        givenMM: null
                                                    })
                                                }}
                                                reset={() => this.resetPreviouslyScanned()}
                                                previouslyScanned={this.props.previouslyScanned}
                                            />
                                        </Step.Content>
                                    </Step>

                                    <Step disabled={this.state.completedStep < 3} active={this.state.completedStep === 3} completed={this.state.completedStep >= 4}>
                                        <Icon name='calendar check' />
                                        <Step.Content>
                                            <Step.Title>{TranslationController.translate("Oznacz zamówienie jako zrealizowane")}</Step.Title>

                                            <div style={{marginTop: 10}}>
                                                {this.state.completedStep < 3 &&
                                                    <div className={"eanScan-disabled"}></div>
                                                }

                                                <Button onClick={() => {
                                                    this.setState({
                                                        dimmer: true
                                                    })

                                                    this.acceptOrder()
                                                    return this.completeStep("4")
                                                }} color={"blue"} icon labelPosition='right'>
                                                    {TranslationController.translate("Towar dostępny na magazynie, przejdź do realizacji")}
                                                    <Icon name='checkmark' />
                                                </Button>
                                            </div>
                                        </Step.Content>
                                    </Step>
                                </React.Fragment>
                            }
                        </Step.Group>

                        {/*<PDFViewer width={1200} height={1000}>*/}
                        {/*<OrderPackingPDF data={this.props.data}/>*/}
                        {/*</PDFViewer>*/}
                    </div>


                    {this.state.dimmer &&
                        <Dimmer active inverted style={{
                            position: "fixed",
                            zIndex: 99999
                        }}>
                            <Loader size='medium'>Proszę czekać... trwa synchronizacja.</Loader>
                        </Dimmer>
                    }
                </div>
            </React.Fragment>
        )
    }
}
