import React from "react";
import PaperTitle from "../../../components/PaperTitle";
import Grid from "@material-ui/core/Grid";
import DataPresentation from "../../../components/DataPresentation";
import {formatDate} from "../../../helpers/dateHelper";
import Button from "../../../components/Button";
import {Comment, Dropdown, Form, Icon, Label, Menu, Message, Table} from "semantic-ui-react";
import {TranslationController} from "../../../controller/menager";
import OrderStatus from "../OrderStatus";
import {OrderStatusesHelper} from "../../../helpers/orderStatuses";
import {DATA_OBJECT} from "../../../controller/dataController";
import DisplayPrice from "../../../components/display/DisplayPrice";
import PaperComponent from "../../../components/Paper";
import {api} from "../../../lib/axios";
import Confirm from "../../../components/Confirm";
import SnackBar from "../../../components/Snack";
import {jsonCopy} from "../../../helpers/lib";
import DeliveryManagement from "../deliveryManagement";
import AllegroLogo from "../../../resources/allegrologo.png";
import ModivoLogo from "../../../resources/modivo-logo.png";
import WszystkoplLogo from "../../../resources/wszystkopl-logo.png";
import EmpikLogo from "../../../resources/empik.png";
import { mmMarketplaces } from "../../../helpers/mmMarketplaces";

export default class OrderGrid extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            conf: [],
            setConf: false
        }
    }

    componentDidMount() {
        this.setConf();
    }

    renderElements(position) {
        if (!this.state.conf.length) return null;

        const editState = this.props.editState;
        const orderData = this.props.orderData;

        if (!orderData) return null;

        let elements = [
            <PaperComponent>
                <PaperTitle title={"Dane podstawowe"}/>
                <Grid container spacing={2}>
                    <Grid container xs={12}>
                        <Grid item xs={12}>
                            {(orderData.type === "allegro" || orderData.allegroData?.marketplace === "allegro") &&
                                <img src={AllegroLogo} style={{
                                    width: 50,
                                    left: 5,
                                    position: "relative"
                                }}/>
                            }

                            {orderData.allegroData?.marketplace === "modivo" &&
                                <img src={ModivoLogo} style={{
                                    width: 50,
                                    left: 5,
                                    position: "relative"
                                }}/>
                            }

                            {orderData.allegroData?.marketplace === "wszystko" &&
                                <img src={WszystkoplLogo} style={{
                                    width: 100,
                                    left: 5,
                                    position: "relative"
                                }}/>
                            }

                            {orderData.allegroData?.marketplace === "empik" &&
                                <img src={EmpikLogo} style={{
                                    width: 100,
                                    left: 5,
                                    position: "relative"
                                }}/>
                            }
                        </Grid>
                    </Grid>

                    <Grid container xs={12}>
                        <Grid item xs={6}>
                            <DataPresentation
                                label={"Nr zamówienia"}
                                data={orderData.id}
                                copy={true}
                                dataStyle={{
                                    fontSize: "1.5em",
                                    fontWeight: 700
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DataPresentation
                                label={"Slug"}
                                data={orderData.initSlug}
                                copy={false}
                                dataStyle={{
                                    fontSize: "1.5em",
                                    fontWeight: 700
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid container xs={12}>
                        <Grid item xs={6}>
                            <DataPresentation
                                label={"Stworzono"}
                                data={formatDate(orderData.createdAt).dateTimeSystem}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <DataPresentation
                                label={"Wysyłka do"}
                                data={orderData.shippingToTime ? formatDate(orderData.shippingToTime).dateNow : "---"}
                            />
                        </Grid>
                    </Grid>

                    <Grid container xs={12}>
                        <Grid item xs={6}>
                            <OrderStatus
                                bg={new OrderStatusesHelper().getColor(orderData.status)}
                                font={new OrderStatusesHelper().getFontColor(orderData.status)}
                                label={new OrderStatusesHelper().getLabel(orderData.status)}
                                status={"Status"}
                            />

                            <OrderStatus
                                bg={orderData.completationStopped ? new OrderStatusesHelper().getColor("S") : new OrderStatusesHelper().getColor(orderData.statusSynchro)}
                                font={orderData.completationStopped ? new OrderStatusesHelper().getFontColor("S") : new OrderStatusesHelper().getFontColor(orderData.statusSynchro)}
                                label={orderData.completationStopped ? new OrderStatusesHelper().getLabel("S") : new OrderStatusesHelper().getLabel(orderData.statusSynchro)}
                                status={"Sync"}
                            />

                            {(orderData.orderPicker !== 'V2' || orderData.country === 'de') && <OrderStatus
                                bg={new OrderStatusesHelper().getColor(orderData.gateStatus || null)}
                                font={new OrderStatusesHelper().getFontColor(orderData.gateStatus || null)}
                                label={new OrderStatusesHelper().getLabel(orderData.gateStatus || null)}
                                status={"GATE"}
                            />}

                            {process.env.REACT_APP_WMS_INTEGRATION === 'true' && <OrderStatus
                                bg={new OrderStatusesHelper().getColor(orderData.wmsStatus || null)}
                                font={new OrderStatusesHelper().getFontColor(orderData.wmsStatus || null)}
                                label={new OrderStatusesHelper().getLabel(orderData.wmsStatus || null)}
                                status={"WMS"}
                            />}

                            {orderData.reservationStatus && <OrderStatus
                                bg={new OrderStatusesHelper().getColor(orderData.reservationStatus)}
                                font={new OrderStatusesHelper().getFontColor(orderData.reservationStatus)}
                                label={new OrderStatusesHelper().getLabel(orderData.reservationStatus)}
                                status={"Rezerwacja"}
                            />
                            }

                            <OrderStatus
                                bg={new OrderStatusesHelper().getColor(orderData.statusShipping)}
                                font={new OrderStatusesHelper().getFontColor(orderData.statusShipping)}
                                label={new OrderStatusesHelper().getLabel(orderData.statusShipping || "---")}
                                status={"Wysyłka"}
                            />

                            {orderData.statusCompletation && (this.props.isParlourOrder || orderData.id.toString().charAt(0) === "8") &&
                                <OrderStatus
                                    bg={new OrderStatusesHelper().getColor(orderData.statusCompletation)}
                                    font={new OrderStatusesHelper().getFontColor(orderData.statusCompletation)}
                                    label={new OrderStatusesHelper().getLabel(orderData.statusCompletation)}
                                    status={"Kompletacja"}
                                />
                            }

                            {
                                (DATA_OBJECT["user"] && DATA_OBJECT["user"].login === "root") &&
                                <OrderStatus
                                    bg={new OrderStatusesHelper().getColor(orderData.statusCompletation)}
                                    font={new OrderStatusesHelper().getFontColor(orderData.statusCompletation)}
                                    label={new OrderStatusesHelper().getLabel(orderData.statusCompletation)}
                                    status={"Kompletacja"}
                                />
                            }
                        </Grid>

                        <Grid item xs={6}>
                            <div style={{
                                display: "flex",
                            }}>
                                <Button onClick={() => {
                                    this.props.controller.toggleCompltation(orderData)
                                }} as='div' labelPosition='right'>
                                    <Button basic color={orderData.completationStopped ? `red` : "blue"}>
                                        <Icon name='fork' />
                                        {TranslationController.translate("Synchronizacja")} {orderData.completationStopped ? TranslationController.translate("zatrzymana") : TranslationController.translate("aktywna")}
                                    </Button>
                                    <Label style={{
                                        fontSize: "0.9em"
                                    }} as='a' color={orderData.completationStopped ? `green` : "red"} pointing='left'>
                                        {orderData.completationStopped ? TranslationController.translate("Wznów") : TranslationController.translate("Zatrzymaj")}
                                    </Label>
                                </Button>
                            </div>

                            <div style={{
                                display: "flex",
                                marginTop: 10,
                                marginBottom: 10,
                                paddingBottom: 10,
                                borderBottom: "1px solid rgb(234 234 234)"
                            }}>
                                <Button
                                    onClick={() => {
                                        this.props.controller.reCompltation(orderData)
                                    }}
                                    as='div'
                                    labelPosition='right'
                                >
                                    <Button basic color={"blue"}>
                                        <Icon name='fork' />
                                        {TranslationController.translate("Ponowna kompletacja")}
                                    </Button>
                                    <Label style={{
                                        fontSize: "0.9em"
                                    }} as='a' color={`blue`} pointing='left'>
                                        {TranslationController.translate("Uruchom")}
                                    </Label>
                                </Button>
                            </div>

                            {(orderData.additionalOptions && orderData.additionalOptions.forceWarehouse) &&
                                <div className={"order-transfered-label"}>
                                    <Icon name={"exclamation triangle"}/>

                                    {TranslationController.translate("Zamówienie zostało przeniesione do") + ` ${orderData.additionalOptions.forceWarehouse.toUpperCase()}`}
                                </div>
                            }

                            {orderData.lastCartUpdate &&
                                <div className={"order-transfered-label"}>
                                    <Icon name={"cart plus"}/>

                                    {TranslationController.translate("Edytowano koszyk")}
                                    <span style={{
                                        fontWeight: "600"
                                    }}>{` - ${orderData.lastCartUpdateUser} [${formatDate(orderData.lastCartUpdate).dateTimeSystem}]`}</span>
                                </div>
                            }
                        </Grid>
                    </Grid>

                    <Grid container xs={12}>
                        <Grid item xs={6}>
                            <DataPresentation
                                label={"Nowy KSK"}
                                data={orderData.newKsk}
                                editable={editState}
                                bool={true}
                                options={[{
                                    text: TranslationController.translate("Tak"),
                                    value: 1
                                }, {
                                    text: TranslationController.translate("Nie"),
                                    value: 0
                                }].map(option => ({
                                    text: option.text,
                                    value: option.value,
                                    key: option.value
                                }))}
                                onChange={(value) => {
                                    orderData.newKsk = parseInt(value) ? 1 : 0;
                                }}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <div className={"es-order-preview-price"}>
                                <DataPresentation
                                    label={"Wartość zamówienia"}
                                    background={"rgba(219, 255, 221, 0.6)"}
                                    icon={"payment"}
                                    data={<DisplayPrice currency={orderData.currency} value={orderData.price}/>}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </PaperComponent>,
            <PaperComponent>
                <PaperTitle title={"Kontakt"}/>

                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        {orderData.addressBilling &&
                            <DataPresentation
                                label={"Telefon"}
                                background={"rgba(204,235,255,.29)"}
                                data={orderData.addressBilling.telephone}
                                icon={"phone"}
                                editable={editState}
                                onChange={(e) => {
                                    orderData.addressBilling.telephone = e;
                                    this.props.controller.refresh()
                                }}
                                copy={true}
                            />
                        }

                        <DataPresentation
                            label={"E-mail (adres wysyłki)"}
                            background={"rgba(204,235,255,.29)"}
                            data={orderData.addressShipping.email}
                            icon={"email"}
                            editable={editState}
                            onChange={(e) => {
                                orderData.addressShipping.email = e;

                                this.props.controller.refresh()
                            }}
                            copy={true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DataPresentation
                            label={"E-mail (użytkownik)"}
                            background={"rgba(204,235,255,.29)"}
                            data={orderData.customerEmail}
                            icon={"email"}
                            editable={editState}
                            onChange={(e) => {
                                orderData.customerEmail = e;
                                orderData.addressShipping.email = e;

                                this.props.controller.refresh()
                            }}
                            copy={true}
                        />

                        <DataPresentation
                            label={"E-mail (adres rozliczeń)"}
                            background={"rgba(204,235,255,.29)"}
                            data={orderData.addressBilling.email}
                            icon={"email"}
                            editable={editState}
                            onChange={(e) => {
                                orderData.addressBilling.email = e;

                                this.props.controller.refresh()
                            }}
                            copy={true}
                        />
                    </Grid>
                </Grid>
            </PaperComponent>,
            orderData.addressBilling ?
                <PaperComponent>
                    <PaperTitle title={"Dane do rozliczeń"}/>

                    {((orderData.addressBilling.street + " " + orderData.addressBilling.streetNumber).length > 32) &&
                        <Message
                            icon='exclamation'
                            header='Zbyt długa nazwa ulicy'
                            content={`Całkowita nazwa ulicy (${orderData.addressBilling.street + " " + orderData.addressBilling.streetNumber}) jest dłuższa niż 32 (${(orderData.addressBilling.street + " " + orderData.addressBilling.streetNumber).length}) znaki i może powodować problemy w systemie Frontier`}
                            error={true}
                        />
                    }

                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <DataPresentation
                                label={"Imię i nazwisko"}
                                data={orderData.addressBilling.firstname}
                                editable={editState}
                                onChange={(e) => {
                                    orderData.addressBilling.firstname = e;
                                    this.props.controller.refresh()
                                }}
                            />
                            <DataPresentation
                                label={"Telefon"}
                                data={orderData.addressBilling.telephone}
                                editable={editState}
                                onChange={(e) => {
                                    orderData.addressBilling.telephone = e
                                    this.props.controller.refresh()
                                }}
                            />
                            {/*<DataPresentation*/}
                            {/*label={"Nazwisko"}*/}
                            {/*data={orderData.addressBilling.lastname}*/}
                            {/*editable={editState}*/}
                            {/*onChange={(e) => {*/}
                            {/*orderData.addressBilling.lastname = e;*/}
                            {/*this.props.controller.refresh()*/}
                            {/*}}*/}
                            {/*/>*/}
                            <DataPresentation
                                label={"Kraj"}
                                data={orderData.addressBilling.country_id}
                                editable={editState}
                                onChange={(e) => {
                                    orderData.addressBilling.country_id = e;
                                    this.props.controller.refresh()
                                }}
                            />
                            <DataPresentation
                                label={"Nazwa firmy"}
                                data={orderData.addressBilling.company}
                                editable={editState}
                                onChange={(e) => {
                                    orderData.addressBilling.company = e
                                    this.props.controller.refresh()
                                }}
                            />
                            <DataPresentation
                                label={"Vat ID"}
                                data={orderData.addressBilling.vat_id}
                                editable={editState}
                                onChange={(e) => {
                                    orderData.addressBilling.vat_id = e
                                    this.props.controller.refresh()
                                }}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <DataPresentation
                                label={"Kod pocztowy"}
                                data={`${orderData.addressBilling.postcode}`}
                                editable={editState}
                                onChange={(e) => {
                                    orderData.addressBilling.postcode = e
                                    this.props.controller.refresh()
                                }}
                            />
                            <DataPresentation
                                label={"Miasto"}
                                data={`${orderData.addressBilling.city}`}
                                editable={editState}
                                onChange={(e) => {
                                    orderData.addressBilling.city = e
                                    this.props.controller.refresh()
                                }}
                            />
                            <DataPresentation
                                label={"Nazwa ulicy"}
                                data={`${orderData.addressBilling.street}`}
                                editable={editState}
                                onChange={(e) => {
                                    orderData.addressBilling.street = e;
                                    this.props.controller.refresh()
                                }}
                            />
                            <DataPresentation
                                label={"Numer ulicy"}
                                data={`${orderData.addressBilling.streetNumber}`}
                                editable={editState}
                                onChange={(e) => {
                                    orderData.addressBilling.streetNumber = e;
                                    this.props.controller.refresh()
                                }}
                            />
                            <DataPresentation
                                label={"Numer lokalu"}
                                data={`${orderData.addressBilling.localNumber}`}
                                editable={editState}
                                onChange={(e) => {
                                    orderData.addressBilling.localNumber = e;
                                    this.props.controller.refresh()
                                }}
                            />
                        </Grid>
                    </Grid>
                </PaperComponent> : null,
            <PaperComponent>
                <PaperTitle title={"Dane do wysyłki"}/>

                {((orderData.addressShipping.street + " " + orderData.addressShipping.streetNumber).length > 32) &&
                    <Message
                        icon='exclamation'
                        header='Zbyt długa nazwa ulicy'
                        content={`Całkowita nazwa ulicy (${orderData.addressShipping.street + " " + orderData.addressShipping.streetNumber}) jest dłuższa niż 32 (${(orderData.addressShipping.street + " " + orderData.addressShipping.streetNumber).length}) znaki i może powodować problemy w systemie Frontier`}
                        error={true}
                    />
                }

                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <DataPresentation
                            label={"Imię i nazwisko"}
                            data={orderData.addressShipping.firstname}
                            editable={editState}
                            onChange={(e) => {
                                orderData.addressShipping.firstname = e;
                                this.props.controller.refresh()
                            }}
                        />
                        <DataPresentation
                            label={"Telefon"}
                            data={orderData.addressShipping.telephone}
                            editable={editState}
                            onChange={(e) => {
                                orderData.addressShipping.telephone = e
                                this.props.controller.refresh()
                            }}
                        />
                        {/*<DataPresentation*/}
                        {/*label={"Nazwisko"}*/}
                        {/*data={orderData.addressShipping.lastname}*/}
                        {/*editable={editState}*/}
                        {/*onChange={(e) => {*/}
                        {/*orderData.addressShipping.lastname = e*/}
                        {/*this.props.controller.refresh()*/}
                        {/*}}*/}
                        {/*/>*/}
                        <DataPresentation
                            label={"Kraj"}
                            data={orderData.addressShipping.country_id}
                            editable={editState}
                            onChange={(e) => {
                                orderData.addressShipping.country_id = e;
                                this.props.controller.refresh()
                            }}
                        />
                        <DataPresentation
                            label={"Nazwa firmy"}
                            data={orderData.addressShipping.company}
                            editable={editState}
                            onChange={(e) => {
                                orderData.addressShipping.company = e
                                this.props.controller.refresh()
                            }}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <DataPresentation
                            label={"Kod pocztowy"}
                            data={`${orderData.addressShipping.postcode}`}
                            editable={editState}
                            onChange={(e) => {
                                orderData.addressShipping.postcode = e
                                this.props.controller.refresh()
                            }}
                        />
                        <DataPresentation
                            label={"Miasto"}
                            data={`${orderData.addressShipping.city}`}
                            editable={editState}
                            onChange={(e) => {
                                orderData.addressShipping.city = e
                                this.props.controller.refresh()
                            }}
                        />
                        <DataPresentation
                            label={"Nazwa ulicy"}
                            data={`${orderData.addressShipping.street}`}
                            editable={editState}
                            onChange={(e) => {
                                orderData.addressShipping.street = e;
                                this.props.controller.refresh()
                            }}
                        />
                        <DataPresentation
                            label={"Numer ulicy"}
                            data={`${orderData.addressShipping.streetNumber}`}
                            editable={editState}
                            onChange={(e) => {
                                orderData.addressShipping.streetNumber = e;
                                this.props.controller.refresh()
                            }}
                        />
                        <DataPresentation
                            label={"Numer lokalu"}
                            data={`${orderData.addressShipping.localNumber}`}
                            editable={editState}
                            onChange={(e) => {
                                orderData.addressShipping.localNumber = e;
                                this.props.controller.refresh()
                            }}
                        />
                    </Grid>
                </Grid>
            </PaperComponent>,

            <DeliveryManagement
                editState={editState}
                orderData={orderData}
                shippingMethods={this.props.shippingMethods}
                shippingMethodsList={this.props.shippingMethodsList}
                state={this.props.state}
                isParlourOrder={this.props.isParlourOrder}
                internalOrder={this.props.internalOrder}
                controller={this.props.controller}
                setState={this.props.setState}
            />,

            <PaperComponent>
                <PaperTitle title={"Komentarze"}/>

                {orderData.comments ?
                    <Comment.Group>
                        {orderData.comments.map((comment, key) => {
                            return (
                                <Comment key={key}>
                                    <Comment.Avatar src='https://react.semantic-ui.com/images/avatar/small/matt.jpg' />
                                    <Comment.Content>
                                        <Comment.Author as='a'>{comment.user}</Comment.Author>
                                        <Comment.Metadata>
                                            <div>{formatDate(comment.timestamp).dateTimeSystem}</div>
                                        </Comment.Metadata>
                                        <Comment.Text>{comment.value}</Comment.Text>
                                        <Comment.Actions>
                                            <Comment.Action
                                                onClick={() => this.props.controller.removeComment(comment)}
                                            >{TranslationController.translate("Usuń")}</Comment.Action>
                                        </Comment.Actions>
                                    </Comment.Content>
                                </Comment>
                            )
                        })}
                    </Comment.Group>
                    : <div style={{
                        textAlign: "center",
                        color: "#c6c6c6",
                        margin: 20
                    }}>{TranslationController.translate("Jeszcze nikt nie dodał żadnego komentarza")}</div>
                }


                <div id={"letter-counter"}>
                    Pozostało znaków: {100 - (this.state.newCommentValue ? this.state.newCommentValue.length : 0)}
                </div>
                <Form
                    reply
                    onSubmit={(value) => {
                        if (!this.props.state.newCommentValue) {
                            return SnackBar("Wprowadzona wartość nie może być pusta.", "error")
                        }

                        if (this.props.state.newCommentValue.length < 1) {
                            return SnackBar("Wprowadzony tekst jest za krótki.", "error")
                        }

                        if (this.props.state.newCommentValue.length > 99) {
                            return SnackBar("Wprowadzony tekst jest za długi. Max 100 znaków", "error")
                        }

                        this.props.controller.addComment();
                    }}
                >
                    <Form.TextArea
                        placeholder={TranslationController.translate("Treść...")}
                        onChange={(e, v) => {
                            this.props.state.newCommentValue = e.target.value;

                            let count = 100 - (this.props.state.newCommentValue ? this.props.state.newCommentValue.length : 0);
                            const element = document.getElementById("letter-counter");

                            if (count < 0) {
                                count = 0;
                                element.style.color = "red";
                            } else {
                                element.style.color = "black";
                            }

                            element.innerText = `Pozostało znaków: ${count}`;
                        }}
                    />
                    <Button content={TranslationController.translate("Dodaj komentarz")} labelPosition='left' icon='edit' primary />
                </Form>
            </PaperComponent>,
            <PaperComponent>
                <PaperTitle title={"Informacje o płatności"}/>

                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <DataPresentation
                            label={"Metoda płatności"}
                            data={(this.props.state.availablePaymentMethods && this.props.state.availablePaymentMethods[orderData.paymentMethodId]) ? this.props.state.availablePaymentMethods[orderData.paymentMethodId].name : null}
                            editable={editState}
                            options={this.props.state.availablePaymentMethodsList.map(method => ({
                                text: method.name,
                                value: {
                                    id: method.id,
                                    title: method.name,
                                },
                                key: method.id
                            }))}
                            onChange={(value) => {
                                orderData.paymentMethod = value.title;
                                orderData.paymentMethodId = value.id;

                                if (this.props.state.orderData) {
                                    this.props.state.paymentMethodId = value.id
                                }
                            }}
                        />
                        <DataPresentation
                            label={"Zapłacono"}
                            bool={true}
                            boolValue={true}
                            data={orderData.statusPayment ? formatDate(orderData.statusPayment).dateTimeSystem : null}
                            editable={editState}
                            options={[{
                                text: TranslationController.translate("Tak"),
                                value: 1
                            }, {
                                text: TranslationController.translate("Nie"),
                                value: 0
                            }].map(option => ({
                                text: option.text,
                                value: option.value,
                                key: option.value
                            }))}
                            onChange={(value) => {
                                orderData.statusPayment = parseInt(value) ? new Date().getTime() : null;

                                api().post("/api/orders/update", {order: {
                                        id: orderData.id,
                                        statusPayment: parseInt(value) ? new Date().getTime() : null
                                    }, paymentUpdate: true})
                            }}
                        />

                        <DataPresentation
                            label={"Wysyłka i obsługa"}
                            data={<DisplayPrice currency={orderData.currency} value={0}/>}
                        />

                        <DataPresentation
                            label={"Łącznie do zapłaty"}
                            background={"rgba(219, 255, 221, 0.6)"}
                            icon={"payment"}
                            data={<DisplayPrice currency={orderData.currency} value={orderData.price}/>}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <DataPresentation
                            label={"Płatność online"}
                            data={orderData.paymentProvider}
                        />

                        {(orderData.orderPicker && orderData.orderPicker === 'V2') ? (orderData.invoiceDetails &&
                            <React.Fragment>
                                <DataPresentation
                                    label={"Nr faktury"}
                                    data={orderData.invoiceDetails.invoiceNumber}
                                />

                                <Button
                                    onClick={() => {
                                        window.open(orderData.invoiceDetails.pdf)
                                    }}
                                >
                                    {TranslationController.translate("Pobierz")}
                                </Button>
                            </React.Fragment>) : (orderData.invoiceNo &&
                            <React.Fragment>
                                <DataPresentation
                                    label={"Nr faktury"}
                                    data={orderData.invoiceNo.replace(/_/g, "/")}
                                />

                                <Button
                                    onClick={() => {
                                        this.props.setState({
                                            dimmer: true
                                        });

                                        api().post("/api/orders/downloadInvoice",
                                            {
                                                orderId: orderData.id,
                                            }).then((r) => {
                                            if (r.data.error) {
                                                return SnackBar(r.data.message, "error")
                                            } else {
                                                if (r.data.fullpath) {
                                                    window.open(r.data.fullpath, "_blank")
                                                }
                                            }

                                            this.props.setState({
                                                dimmer: false
                                            });
                                        })
                                    }}
                                >
                                    {TranslationController.translate("Pobierz")}
                                </Button>
                            </React.Fragment>)
                        }

                        {
                            mmMarketplaces.includes(this.props?.orderData?.allegroData?.marketplace) &&
                            this.props.orderData.mmDocumentNumber
                                ? <React.Fragment>
                                    <DataPresentation
                                        label={"Nr dokumentu MM-"}
                                        data={this.props.orderData.mmDocumentNumber}
                                    />
                                </React.Fragment>
                                : <React.Fragment/>
                        }

                        {(orderData.erpCorrection && orderData.erpCorrection.numer) &&
                            <React.Fragment>
                                <DataPresentation
                                    label={"Nr korekty"}
                                    data={orderData.erpCorrection.numer.replace(/_/g, "/")}
                                />

                                <Button
                                    onClick={() => {
                                        if (orderData.erpCorrection.numer) {
                                            api().post("/api/orders/downloadInvoice",
                                                {
                                                    orderId: orderData.id,
                                                    forceDocumentNumber: orderData.erpCorrection.numer.toString().replace(/\//g, '_')
                                                }).then((r) => {
                                                    if (orderData.orderPicker === 'V2') {
                                                        window.open(orderData.erpCorrection.pdf)
                                                    } else {
                                                        if (r.data.error) {
                                                            return SnackBar(r.data.message, "error")
                                                        } else {
                                                            if (r.data.fullpath) {
                                                                window.open(r.data.fullpath, "_blank")
                                                            }
                                                        }
                                                    }
                                                this.props.setState({
                                                    dimmer: false
                                                });
                                            })
                                        }
                                    }}
                                >
                                    {TranslationController.translate("Pobierz")}
                                </Button>
                            </React.Fragment>
                        }
                    </Grid>

                    {orderData.paymentMethodId && orderData.paymentMethodId.includes("bluemedia") &&
                        <Grid item xs={12}>
                            <DataPresentation
                                label={"Link płatności online"}
                                data={orderData.paymentUrl}
                            />
                            <Button
                                onClick={() => {
                                    Confirm("Czy na pewno chcesz wygenerować nowy link płatności online?", null, () => {
                                        this.props.setState({
                                            dimmer: true
                                        })

                                        api().post("/api/orders/generateNewPaymentUrl",
                                            {
                                                orderId: orderData.id,
                                                paymentMethodId: orderData.paymentMethodId
                                            }).then((r) => {
                                            this.props.setState({
                                                dimmer: false
                                            })

                                            if (r.data.error) {
                                                return SnackBar(r.data.message, "error")
                                            } else {
                                                this.props.state.orderData.paymentUrl = r.data.paymentUrl;

                                                this.props.controller.forceUpdate();

                                                SnackBar("Wygenerowano nowy link płatności")
                                            }
                                        })
                                    })
                                }}
                            >
                                Generuj nowy link
                            </Button>
                        </Grid>
                    }
                    {orderData.paymentMethodId && orderData.paymentMethodId.includes("paypo") &&
                        <Grid item xs={12}>
                            <DataPresentation
                            label={"Link płatności online"}
                            data={orderData.paymentUrl}
                            copy={true}
                            />
                        </Grid>
                    }
                </Grid>
            </PaperComponent>,
            <PaperComponent>
                <PaperTitle title={"Kupony i karty"}/>

                <Grid container spacing={2}>
                    <Grid container xs={12}>
                        <Grid item xs={6}>
                            <DataPresentation
                                label={"Kod rabatowy"}
                                data={orderData.promotionCode}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <DataPresentation
                                label={"Numer karty EsotiqCLUB"}
                                data={orderData.cardNumber ? orderData.cardNumber : "---"}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </PaperComponent>,
            (orderData.paymentHistory && orderData.paymentHistory.length) ?
                <PaperComponent>
                    <PaperTitle title={"Historia płatności"}/>

                    <div>
                        <Table compact>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>{TranslationController.translate("Kwota")}</Table.HeaderCell>
                                    <Table.HeaderCell>{TranslationController.translate("ID")}</Table.HeaderCell>
                                    <Table.HeaderCell>{TranslationController.translate("Data")}</Table.HeaderCell>
                                    <Table.HeaderCell>{TranslationController.translate("Status")}</Table.HeaderCell>
                                    <Table.HeaderCell>{TranslationController.translate("Źródło")}</Table.HeaderCell>
                                    <Table.HeaderCell>{TranslationController.translate("Zwrot")}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {orderData.paymentHistory.map((e, k) => {
                                    return (
                                        <Table.Row key={k}>
                                            <Table.Cell>
                                                <DisplayPrice currency={orderData.currency} value={e.additionalData.updateData.price}/>
                                            </Table.Cell>
                                            <Table.Cell className={"prlour-preview-cell"}>
                                                {(e.additionalData.updateData.name.includes("Paypal") ? e.additionalData.updateData.paymentId : e.additionalData.updateData.id) || (e.additionalData.updateData.status === "new_payment" ? TranslationController.translate("Zarejestrowano nową płatność") : TranslationController.translate("Oczekuje na opłacenie"))}
                                            </Table.Cell>
                                            <Table.Cell className={"prlour-preview-cell"}>
                                                {formatDate(e.additionalData.updateData.created).dateTimeSystem}
                                            </Table.Cell>
                                            <Table.Cell className={"prlour-preview-cell"}>
                                                {new OrderStatusesHelper().getLabel(e.additionalData.updateData.status)}
                                            </Table.Cell>
                                            <Table.Cell className={"prlour-preview-cell"}>
                                                {e.additionalData.updateData.userId}
                                            </Table.Cell>

                                            {e.additionalData.updateData.status === "paid" &&
                                                <Table.Cell className={"prlour-preview-cell"}>
                                                    {orderData && orderData.paymentMethodId && (orderData.paymentMethodId.includes("bluemedia") || orderData.paymentMethodId.includes("klarna")) && orderData.paymentData && orderData.paymentData.external_id &&
                                                        <Button
                                                            onClick={() => {
                                                                this.props.setState({
                                                                    paymentRevertModal: true,
                                                                    paymentRevertPaypo: false,
                                                                    paymentRevertAmount: Math.abs(parseFloat(e.additionalData.updateData.price).toFixed(2))
                                                                })
                                                            }}
                                                        >
                                                            Zwrot
                                                        </Button>
                                                    }

                                                    {orderData && orderData.paymentMethodId && orderData.paymentMethodId.includes("paypo") && orderData.paymentData && orderData.paymentData.external_id &&
                                                        <Button
                                                            onClick={() => {
                                                                this.props.setState({
                                                                    paymentRevertModal: true,
                                                                    paymentRevertPaypo: true,
                                                                    paymentRevertAmount: Math.abs(parseFloat(e.additionalData.updateData.price).toFixed(2))
                                                                })
                                                            }}
                                                        >
                                                            Zwrot
                                                        </Button>
                                                    }
                                                </Table.Cell>
                                            }
                                        </Table.Row>
                                    )
                                })}
                            </Table.Body>
                        </Table>
                    </div>

                    {/*<div>*/}
                    {/*    <Button*/}
                    {/*        onClick={() => {*/}
                    {/*            this.props.setState({*/}
                    {/*                addPaymentModal: true*/}
                    {/*            })*/}
                    {/*        }}*/}
                    {/*    >*/}
                    {/*        Dodaj płatność*/}
                    {/*    </Button>*/}
                    {/*</div>*/}
                </PaperComponent> : <div/>
        ]

        let {conf} = this.state;

        if (!conf) return;
        if (!conf.length) return;

        let renderElements = [];

        elements = elements.filter(e => e);
        elements.forEach((e, k) => {
            if (conf[k] && conf[k].position === position) {
                renderElements.push({
                    jsx: e,
                    key: k
                })
            }
        })

        const trimConf = () => {
            if (conf.length > elements.length) {
                conf.pop();
            }

            if (conf.length > elements.length) {
                trimConf();
            }
        }

        trimConf();

        elements = renderElements;

        let sortElements = [];

        elements.forEach((e, k) => {
            sortElements.push({
                jsx: e.jsx,
                sortKey: conf[e.key].order,
                key: e.key
            })
        })

        sortElements = sortElements.sort((a, b) => (a.sortKey > b.sortKey) ? 1 : -1)

        return sortElements.map((element, key) => {
            return <div style={{
                position: "relative"
            }}>
                <Menu style={{
                    position: "absolute",
                    right: 0,
                    top: 4,
                    zIndex: 9,
                }} className={"menu-grid"}>
                    <Menu.Menu position='right' className={"menu-grid"}>
                        <Dropdown
                            button
                            className={"icon"}
                            icon={"arrows alternate"}
                            direction={"right"}
                        >
                            <Dropdown.Menu>
                                <Dropdown.Menu scrolling>
                                    {[
                                        {key: "up", value: "up", text: TranslationController.translate("W góre")},
                                        {key: "down", value: "down", text: TranslationController.translate("W dół")},
                                        {key: "left", value: "left", text: TranslationController.translate("W lewo")},
                                        {key: "right", value: "right", text: TranslationController.translate("W prawo")}
                                    ].map((option) => (
                                        <Dropdown.Item key={option.value} {...option} onClick={() => {
                                            let currentConf;
                                            let nextConf;
                                            let currentConfOrder;
                                            let nextConfOrder;

                                            switch (option.value) {
                                                case "up":
                                                    conf.map(e => {
                                                        if (e.position === position) {
                                                            if (e.order === element.sortKey) {
                                                                currentConf = e;
                                                            }

                                                            if (e.order === (element.sortKey - 1)) {
                                                                nextConf = e;
                                                            }
                                                        }
                                                    })

                                                    break;
                                                case "down":
                                                    conf.map(e => {
                                                        if (e.position === position) {
                                                            if (e.order === element.sortKey) {
                                                                currentConf = e;
                                                            }

                                                            if (e.order === (element.sortKey + 1)) {
                                                                nextConf = e;
                                                            }
                                                        }
                                                    })

                                                    break;
                                                case "left":
                                                    conf.map(e => {
                                                        if (e.position === position) {
                                                            if (e.order === element.sortKey) {
                                                                let lastOrderKey = 0;

                                                                conf.forEach(i => {
                                                                    if (i.position !== position && i.order > lastOrderKey) {
                                                                        lastOrderKey = i.order;
                                                                    }
                                                                })

                                                                if (e.position === "left") {
                                                                    e.position = "right";
                                                                } else {
                                                                    e.position = "left";
                                                                }

                                                                e.order = lastOrderKey + 1;
                                                            }
                                                        }
                                                    })

                                                    break;
                                                case "right":
                                                    conf.map(e => {
                                                        if (e.position === position) {
                                                            if (e.order === element.sortKey) {
                                                                let lastOrderKey = 0;

                                                                conf.forEach(i => {
                                                                    if (i.position !== position && i.order > lastOrderKey) {
                                                                        lastOrderKey = i.order;
                                                                    }
                                                                })

                                                                if (e.position === "right") {
                                                                    e.position = "left";
                                                                } else {
                                                                    e.position = "right";
                                                                }

                                                                e.order = lastOrderKey + 1;
                                                            }
                                                        }
                                                    })

                                                    break;
                                            }

                                            if (currentConf && nextConf) {
                                                currentConfOrder = jsonCopy(currentConf.order);
                                                nextConfOrder = jsonCopy(nextConf.order);

                                                currentConf.order = nextConfOrder;
                                                nextConf.order = currentConfOrder;
                                            }

                                            this.reindex();

                                            localStorage.setItem("order_grid", JSON.stringify(conf));

                                            this.forceUpdate();
                                        }}/>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Menu.Menu>
                </Menu>

                {element.jsx}
            </div>
        });
    }

    reindex() {
        const {conf} = this.state;

        const leftGrid = [];
        const rightGrid = [];

        conf.map(e => {
            if (e.position === "left") leftGrid.push(e);
            else rightGrid.push(e);
        })

        const runReindex = (values) => {
            const numArray = values.map(e => e.order).sort((a, b) => a > b ? 1 : -1);

            let isMissing = false;

            for (let i = 1; i < numArray.length; i++) {
                const result = numArray[i] - numArray[i-1];
                if (result > 0 && result != 1) {
                    values[i].order = numArray[i] - 1;

                    isMissing = true;
                }
            }

            if (isMissing) {
                runReindex(values);
            }
        }

        runReindex(leftGrid);
        runReindex(rightGrid);
    }

    setConf() {
        const localConf = localStorage.getItem("order_grid");

        this.setState({
            conf: localConf ? JSON.parse(localConf) : [
                {position: "left", order: 1},
                {position: "left", order: 2},
                {position: "left", order: 3},
                {position: "left", order: 4},
                {position: "right", order: 1},
                {position: "right", order: 2},
                {position: "right", order: 3},
                {position: "right", order: 4},
                {position: "right", order: 4},
            ]
        })
    }

    render() {
        return (
            <div className={"order-grid"}>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        {this.props.state.orderData ? this.renderElements("left") : null}
                    </Grid>

                    <Grid item xs={6}>
                        {this.props.state.orderData ? this.renderElements("right") : null}
                    </Grid>
                </Grid>
            </div>
        );
    }
}
